$text_width: 960px;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 8px;
}

.controller {
  display: grid;
  grid-template-columns: 200pt 25pt 60pt 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 15px;

  margin-top: 7px;
  margin-bottom: 10px;

  .fromName {
    grid-area: 1 / 1 / 2 / 2;
  }
  .toName {
    grid-area: 2 / 1 / 3 / 2;
  }
  .exchange {
    grid-area: 1 / 2 / 3 / 3;
  }
  .print {
    grid-area: 1 / 3 / 3 / 3;
  }
}

.header_notice {
  width: $text_width;
  background-color: #EEE;
  margin-bottom: 1em;
  padding: 0.5em;
}

.credit {
  font-size: small;
  width: $text_width;
  text-align: center;
}

.none {
  display: none;
}

@page {
  margin: 5mm;
}

@media print {
  body {
    zoom: 0.68;
  }
}
